import React from 'react'
import { Box, Stack, Text } from '@chakra-ui/react'

function FeaturedImage({ image, caption }) {
  return (
    <Stack>
      <Box position="relative" paddingBottom="66%" overflow="hidden" marginBottom={1}>
        {image}
      </Box>
      <Text
        fontSize="sm"
        color="primary.500"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        paddingBottom={2}
      >
        {caption}
      </Text>
    </Stack>
  )
}

export default FeaturedImage
