import * as React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Article from '../components/article'
import Section from '../components/section'
import { H1, H2, P } from '../components/globals'
import { Flex, Stack, Text } from '@chakra-ui/layout'
import Img from '../components/img'
import FeaturedImage from '../components/featuredImage'

const Content = styled.div`
  h2 {
    font-size: ${(props) => props.theme.fontSizes['2xl']};
    margin-top: ${(props) => props.theme.space[10]};
    font-weight: bold;
    font-family: 'Source Sans Pro';
  }

  p,
  li,
  span,
  a,
  strong {
    font-size: ${(props) => props.theme.fontSizes['xl']};
    line-height: ${(props) => props.theme.lineHeights['tall']};
    letter-spacing: ${(props) => props.theme.letterSpacings['wide']};
    font-family: Lora, serif !important;
    font-weight: inherit;
  }

  p {
    margin-top: ${(props) => props.theme.space[6]};
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    margin-left: ${(props) => props.theme.space[8]};
    margin-top: ${(props) => props.theme.space[2]};
    display: flex;
    flex-direction: column;
  }

  li {
    margin-top: ${(props) => props.theme.space[2]};
    list-style-position: outside;

    &::marker {
      font-weight: bold;
    }
  }

  blockquote {
    color: ${(props) => props.theme.colors.primary[500]};
    padding: ${(props) => props.theme.space[3]};
    border-top: 1px solid ${(props) => props.theme.colors.gray[200]};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[200]};
    margin-top: ${(props) => props.theme.space[6]};
    font-weight: bold;
    text-align: center;
    position: relative;

    p {
      margin: 0;
      font-size: ${(props) => props.theme.fontSizes['2xl']};
    }

    p::before {
      content: '“';
      font-size: ${(props) => props.theme.fontSizes['2xl']};
    }

    p::after {
      content: '”';
      font-size: ${(props) => props.theme.fontSizes['2xl']};
    }
  }
`

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.subtitle}>
      <Article>
        <Section>
          <Stack>
            <H1 fontSize="3xl">{post.frontmatter.title}</H1>
            <H2 fontSize="xl">{post.frontmatter.subtitle}</H2>
          </Stack>
          <Flex
            borderTop="1px solid"
            borderTopColor="gray.200"
            borderBottom="1px solid"
            borderBottomColor="gray.200"
            paddingY={2}
            marginY={4}
            justifyContent="space-between"
          >
            <Stack spacing={0}>
              <Text fontWeight="bold" color="primary.500">
                Por Redacción
              </Text>
              <P textTransform="capitalize">{post.frontmatter.date}</P>
            </Stack>
          </Flex>
          <FeaturedImage
            image={
              <Img
                fixed={post.frontmatter.featuredImage.childImageSharp.fixed}
                layout="background"
              />
            }
            caption={post.frontmatter.captionImage}
          />
          <Content dangerouslySetInnerHTML={{ __html: post.html }} />
        </Section>
      </Article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY", locale: "es")
        captionImage
        featuredImage {
          childImageSharp {
            fixed(width: 1024, quality: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
